<template>
    <div>
        <v-list-item v-if="mIsSelecting" @click="mIsSelecting = false" :dark="dark">
            <v-list-item-content>
                <v-list-item-subtitle>{{ label }}</v-list-item-subtitle>
                <v-list-item-subtitle v-if="sublabel != null">{{ sublabel }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
                <v-row class="ma-0 pa-0">
                    <slot name="actions" />

                    <v-icon>mdi-chevron-up</v-icon>
                    <slot name="actionsRight" />
                    <v-menu 
                        v-if="showSettings"
                        offset-y 
                        :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn 
                                icon
                                v-bind="attrs"
                                v-on="on"
                                title="Settings"
                                :small="small">
                                <v-icon :small="small">mdi-cog</v-icon>
                            </v-btn>
                        </template>
                        <v-list :dense="dense">
                            <slot name="settings" />
                        </v-list>
                    </v-menu>
                </v-row>
            </v-list-item-action>
        </v-list-item>
        <v-slide-y-transition hide-on-leave group>
            <v-text-field
                v-if="isLengthyArray(searchProperties) && mIsSelecting"
                flat
                :dark="dark"
                dense
                hide-details
                hide-no-data
                key="searchK"
                solo-inverted
                small
                single-line
                prepend-inner-icon="mdi-close"
                append-icon="mdi-magnify"
                label="Find"
                v-model="searchInput"
                @click:prepend-inner="searchInput = null" />
            <v-list
                v-if="mIsSelecting"
                :dark="dark"
                :dense="dense"
                :flat="flat"
                :height="height"
                :max-height="maxHeight"
                key="1"
                class="overflow-y-auto"
                :class="transparent ? 'transparent' : null">
                <v-slide-x-transition group>
                    <v-list-item-group
                        v-model="selectedItem"
                        @change="validateUpdate"
                        :active-class="activeClass"
                        :mandatory="value != null && !canDeselect"
                        key="listKey">
                        <template v-for="(item, index) in filteredItems">
                            <slot name="listItem" :item="item" :index="index">
                                <v-list-item
                                    v-if="item != null"
                                    :active-class="activeClass"
                                    :key="index"
                                    :value="item"
                                    :two-line="twoLine"
                                    :three-line="threeLine"
                                    :dense="dense">
                                    <slot v-bind:item="item">
                                        {{ nestVal(item, itemText, textFilter) }}
                                    </slot>
                                </v-list-item>
                            </slot>
                            <v-divider v-if="dividers" :key="'d' + index" />
                        </template>
                    </v-list-item-group>
                </v-slide-x-transition>
            </v-list>
        
            <div v-else key="2">
                <slot name="selectedItem"
                    v-bind:open="() => { if (isEditing) { mIsSelecting = true } }"
                    v-bind:item="selectedItem">
                    <v-list-item 
                        @click="() => { if (isEditing) { mIsSelecting = true } }"
                        :dark="dark">
                        <v-list-item-content>
                            <v-list-item-subtitle>{{ label }}</v-list-item-subtitle>
                            <v-list-item-title>
                                <slot name="selection" v-bind:item="selectedItem">
                                    <v-chip v-if="chips && selectedItem != null">
                                        {{ nestVal(selectedItem, itemText, textFilter) }}
                                    </v-chip>
                                    <span v-else-if="selectedItem != null">
                                        {{ nestVal(selectedItem, itemText, textFilter) }}
                                    </span>
                                    <span v-else>{{ placeholder }}</span>
                                </slot>
                            </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-row no-gutters>
                                <slot name="actions" />
                                <v-icon>mdi-chevron-down</v-icon>
                                <slot name="actionsRight" />
                                <v-menu
                                    v-if="showSettings"
                                    offset-y 
                                    :close-on-content-click="false">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn 
                                            icon
                                            v-bind="attrs"
                                            v-on="on"
                                            title="Settings"
                                            :small="small">
                                            <v-icon :small="small">mdi-cog</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list :dense="dense">
                                        <slot name="settings" />
                                    </v-list>
                                </v-menu>
                            </v-row>
                        </v-list-item-action>
                    </v-list-item>
                </slot>
            </div>
        </v-slide-y-transition>
        
        <v-overlay :value="isLoading" absolute class="text-center">
            <v-progress-circular indeterminate size="64" />
            <p>Loading</p>
        </v-overlay>
    </div>
</template>

<script>
export default {
    name: 'BT-Select-List-Box',
    data: function() {
        return {
            asyncItems: [],
            errorMessage: null,
            isLoading: false,
            // lastSelectedItem: null,
            mIsSelecting: false,
            mParams: null,
            searchInput: null,
            selectedItem: null,
            showError: false,
            validItem: null,
        }
    },
    props: {
        activeClass: {
            type: String,
            default: 'accent--text'
        },
        //canSearch: can seach if there are search properties
        canDeselect: {
            type: Boolean,
            default: true
        },
        canSelectNone: {
            type: Boolean,
            default: true
        },
        chips: {
            type: Boolean,
            default: false
        },
        customURL: {
            type: String,
            default: null
        },
        dark: {
            type: Boolean,
            default: undefined
        },
        dense: {
            type: Boolean,
            default: false
        },
        dividers: {
            type: Boolean,
            default: true
        },
        flat: {
            type: Boolean,
            default: false,
        },
        height: {
            type: String,
            default: 'auto'
        },
        hideRefresh: {
            type: Boolean,
            default: false
        },
        isEditing: {
            type: Boolean,
            default: false
        },
        isSelecting: {
            type: Boolean,
            default: false
        },
        isSelectingNullValue: {
            type: Boolean,
            default: false
        },
        isSingle: {
            type: Boolean,
            default: false
        },
        itemID: {
            type: String,
            default: null
        },
        items: {
            type: Array,
            default: null
        },
        itemText: {
            type: String,
            default: null
        },
        itemValue: {
            type: String,
            default: 'id'
        },
        label: {
            type: String,
            default: null
        },
        maxHeight: {
            type: String,
            default: '100%'
        },
        navigation: {
            type: String,
            default: null
        },
        onCanSelect: {
            type: Function,
            default: null
        },
        onFilter: {
            type: Function,
            default: null
        },
        onPullSuccessAsync: {
            type: Function,
            default: null
        },
        params: {
            type: Object,
            default: null // () => null
        },
        placeholder: {
            type: String,
            default: 'Select'
        },
        proxyID: {
            type: String,
            default: null
        },
        refreshToggle: {
            type: Boolean,
            default: false
        },
        returnCSV: {
            type: Boolean,
            default: false
        },
        returnObject: {
            type: Boolean,
            default: false
        },
        searchString: {
            type: String,
            default: null
        },
        searchProperties: {
            type: Array,
            default: null
        },
        selectIfOneOption: {
            type: Boolean,
            default: false
        },
        showSettings: {
            type: Boolean,
            default: false
        },
        small: {
            type: Boolean,
            default: false
        },
        sublabel: {
            type: String,
            default: null
        },
        textFilter: {
            type: String,
            default: null
        },
        transparent: {
            type: Boolean,
            default: false
        },
        twoLine: {
            type: Boolean,
            default: false
        },
        threeLine: {
            type: Boolean,
            default: false
        },
        value: null,
        width: {
            type: String,
            default: 'auto'
        },
    },
    async mounted() {
        this.mIsSelecting = this.isSelecting || (this.isSelectingNullValue && this.value == null);

        if (this.params != null) {
            this.mParams = this.params;
        }
        
        await this.pullItems();
    },
    watch: {
        items() {
            this.pullItems();
        },
        params: function(v) {
            if (v != this.mParams) {
                this.mParams = v;
            }
        },
        proxyID() {
            this.pullItems();
        },
        refreshToggle: function() {
            this.refresh();
        },
        searchString: function(val) {
            this.searchInput = val;
        },
        validItem: function(val) {
            this.update(val);
        },
        value(val) {
            if (val == null) {
                this.selectedItem = null;
            }
            else {
                var f = this.filteredItems.find(x => x[this.itemValue] == this.value);
                if ((val == null && f == null) || f == null) {
                    this.loadSelection();
                }
            }
            
        }
    },
    computed: {
        filteredItems() {
            var l = this.asyncItems;
            if (l == null) {
                l = [];
            }
            if (this.searchInput != null && this.isLengthyArray(this.searchProperties)) {
                l = l.filter(y => this.hasSearch(y, this.searchInput, this.searchProperties))
            }
            return this.onFilter ? this.onFilter(l) : l;
        },
        showRefreshButton() {
            return !this.hideRefresh && !this.isLengthyArray(this.asyncItems) && this.navigation != null; //make sure it is navigation based
        }
    },
    methods: {
        validateUpdate(v) {
            if (this.onCanSelect == null || this.onCanSelect(v)) {
                this.validItem = v;
            }
        },
        formError(err) {
            this.showError = true;
            this.errorMessage = this.extractErrorDescription(err);
        },
        loadSelection() {
            if (this.returnCSV) {
                if (this.itemValue != null && this.value != null) {
                    var options = this.value.split(',');
                    this.selectedItem = this.asyncItems.filter(x => options.some(opt => opt == x[this.itemValue]));
                }
                else {
                    this.selectedItem = this.value ? this.value.split(',') : null;
                }
            }
            else {
                if (this.value != null) {
                    if (this.returnObject) {
                        this.selectedItem = this.value;
                    }
                    else if (this.itemValue != null) {
                        this.selectedItem = this.filteredItems.find(x => x[this.itemValue] == this.value);
                    }
                }
            }
        },
        nestVal(item, path, filter) {
            var t = item;

            if (path != null) {
                t = this.getNestedValue(item, path);
            }
            
            if (filter != null) {
                return this.$options.filters[filter](t);
            }
            else {
                return t;
            }
        },
        async pullItems(refresh = false) {
            if (this.items != null) {
                this.asyncItems = this.items;
                this.loadSelection();
                return;
            }

            if (this.navigation == null) {
                return;
            }

            try {
                this.isLoading = true;
                
                var cURL = null;
                if (this.customURL != null) {
                    cURL = this.customURL;
                    if (this.itemID != null) {
                        cURL = cURL.replace('{id}', this.itemID);
                    }
                }

                var res = null;
                if (this.isSingle) {
                    res = await this.$BlitzIt.store.get(this.navigation, this.itemID, this.mParams, refresh, this.proxyID, cURL);
                }
                else {
                    res = await this.$BlitzIt.store.getAll(this.navigation, this.mParams, refresh, this.proxyID, cURL);
                }
                
                if (this.onPullSuccessAsync != null) {
                    this.asyncItems = await this.onPullSuccessAsync(res, refresh);
                }
                else {
                    this.asyncItems = res;
                }

                if (this.selectIfOneOption && this.isArrayOfLength(this.asyncItems, 1) && this.value == null) {
                    this.selectedItem = this.asyncItems[0];
                    this.update(this.asyncItems[0]);
                }

                this.loadSelection();
            }
            catch (err) {
                this.formError(err);
            }
            finally {
                this.isLoading = false;
            }
        },
        refresh() {
            this.showError = false;
            this.errorMessage = null;
            this.pullItems(true);
        },
        update(v) {
            this.mIsSelecting = false;

            if (v == null) {
                this.$emit('input', null)
                this.$emit('change', null);
                return;
            }
            
            var r = null;
            if (this.returnObject) {
                r = v;
            }
            else if (this.returnCSV) {
                if (this.itemValue != null) {
                    this.$emit('input', v.map(x => x[this.itemValue]).toString())
                    this.$emit('change', v.map(x => x[this.itemValue]).toString());
                }
                else {
                    this.$emit('input', v.toString())
                    this.$emit('change', v.toString());
                }
                return;
            }
            else if (this.itemValue) {
                r = v[this.itemValue];
            }
            else {
                r = v;
            }

            // if (r != null && r === this.lastSelectedItem && this.canDeselect) {
            //     this.lastSelectedItem = null;
            // }
            // else {
            //     this.lastSelectedItem = r;
            // }

            // this.$emit('select', this.selec);
            this.$emit('input', r);
            this.$emit('change', r);
        }
    }
}
</script>